<template>
    <div>
      <page-title
        :heading="$t('settings.lang_accountUsage')"
        :subheading="$t('settings.lang_accountUsage')"
        :icon="icon"
      ></page-title>
      <div class="app-main__inner">
        <AccountUsageComponent />
      </div>
    </div>
  </template>
  
  <script>
  import PageTitle from "../../../Layout/Components/PageTitle.vue";
  import AccountUsageComponent from "../../../components/settings/moduleSettings/AccountUsageComponent";
  
  export default {
    components: {
      PageTitle,
      AccountUsageComponent,
    },
  
    data: () => ({
      icon: "pe-7s-plane icon-gradient bg-tempting-azure",
    }),
  };
  </script>
  
<template>
  <div>
    <v-data-table :headers="dataTableHeaders" :items="dataTableItems" />
  </div>
</template>

<script>
import { ENDPOINTS } from "../../../config";
import { Events } from "../../../plugins/events";
import Datatable from "../../datatable/Datatable";

export default {
  name: "AccountUsageComponent",
  components: { Datatable },

  data() {
    return {
      ENDPOINTS,
      dataTableItems: [],
    };
  },
  computed: {
    dataTableHeaders() {
      return [
        {
          text: this.$t("settings.lang_serviceName"),
          value: "service_name",
        },
        {
          text: this.$t("settings.lang_unitsAvailable"),
          value: "units_available",
        },
        {
          text: this.$t("settings.lang_unitsUsed"),
          value: "units_used",
        },
      ];
    },
  },
  methods: {
    prepareDataTable(data) {
      data.forEach((el, i) => {
        if (el["service_name"] === "fiskaly_de") {
          el["service_name"] = "Cloud TSE DE";
        }

        if (el["service_name"] === "fiskaly_at") {
          el["service_name"] = "Cloud TSE AT";
        }

        if (el["service_name"] === "sms_gateway") {
          el["service_name"] = "SMS Gateway";
        }
      });

      this.dataTableItems = data;
    },
    getData() {
      this.loading = true;

      this.axios
        .post(ENDPOINTS.SETTINGS.MODULESETTINGS.ACCOUNT_USAGE.GET, {})
        .then((res) => {
          if (res.data.status == true) {
            if (res.data.data.length > 0) {
              this.prepareDataTable(res.data.data);
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t("generic.lang_nothingtoshow"),
                color: "warning",
              });
            }
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred"),
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: err.message,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getData();
  },
};
</script>
